const paymentConstants = Object.freeze({
  PAYMENT_METHOD_CARD: 'Card',
  PAYMENT_METHOD_APPLE_PAY: 'Apple Pay',
  PAYMENT_METHOD_GOOGLE_PAY: 'Google Pay',
  PAYMENT_METHOD_PAYPAL: 'PayPal',
  THREE_SKU_SUPPORTED_COUNTRIES: [
    'US',
    'CA',
    'AU',
    'NZ',
    'UK',
    'ES',
    'DE',
    'FR',
    'MX',
  ],
});

export default paymentConstants;
