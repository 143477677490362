const routeConstants = {
  // Pages
  PAGE_SIGNUP: 'signup',
  PAGE_SUBSCRIBE: 'subscribe',
  PAGE_GET_APP: 'getapp',
  PAGE_TOPICS: 'topics',
  PAGE_EXPLORE: 'explore',
  PAGE_CHANNELS: 'channels',
  PAGE_TRACK: 'track',
  PAGE_COACHES: 'coaches',
  PAGE_VIEW_ALL: 'view-all',
  PAGE_REFER: 'refer',
  PAGE_AMBASSADOR: 'ambassador',
  PAGE_AURA: 'aura',
  PAGE_SIMILAR_TRACKS: 'similar-tracks',
  PAGE_YOUR_PLAN: 'your-plan',
  PAGE_COACHING: 'coaching',
  PAGE_COACHING_PLAN: 'coaching-plan',
  PAGE_UPSELL: 'upsell',
  PAGE_LIVE: 'live',
  PAGE_SERIES: 'series',
  PAGE_PLAYLIST: 'playlist',
  PAGE_UPCOMING: 'upcoming',
  PAGE_LIVE_REPLAY: 'replay',
  PAGE_TRACKS: 'tracks',
  PAGE_CELEBRITIES: 'celebrities',
  PAGE_CHALLENGES: 'challenges',
  PAGE_GUEST_PASS: 'guest-pass',
  PAGE_FAQ_DELETE_ACCOUNT: 'faq-delete-account',
  PAGE_VIDEO_COACHING: 'video-coaching',
  PAGE_COURSES: 'courses',
  PAGE_EVENTS: 'events',
  PAGE_COMMUNITIES: 'communities',

  // NoIndex Pages (Pages listed here will not be added to sitemap. Make sure to update whenever a new noIndex page is added)
  NO_INDEX_PAGES: [
    '/getapp',
    '/signup',
    '/subscribe',
    '/refer',
    '/ambassador',
    '/your-plan',
    '/subscribe/upsell',
  ],

  // Pages that are part of the signup flow
  ONBOARDING_PAGES: [
    '/signup',
    '/subscribe',
    '/your-plan',
    '/coaching-plan',
    '/subscribe/upsell',
    '/celebrities/[slugCelebrity]',
  ],

  // Slugs
  SLUG_PRICING: '[pricing]',
  SLUG_MEDITATION: 'slugMeditation',
  SLUG_CHANNEL: 'slugChannel',
  SLUG_SERIES: '[slugSeries]',
  SLUG_TOPIC_ID: '[topicId]',
  SLUG_TRACK_ID: '[trackId]',
  SLUG_TYPE: '[type]',
  SLUG_COACH: '[slugCoach]',
  SLUG_USER_ID: '[userId]',
  SLUG_MUSIC_AND_SOUNDS: 'music-and-sounds',
  SLUG_CATEGORY: '[slugCategory]',
  SLUG_CELEBRITY: '[slugCelebrity]',
  SLUG_COURSE: '[slugCourse]',
  SLUG_COMMUNITY: '[slugCommunity]',
  SLUG_EVENT: '[slugEvent]',
  SLUG_REFERRAL_CODE: '[referralCode]',
  SLUG_CHALLENGE_ID: '[challengeId]',
  SLUG_LIVE_ID: '[liveEventId]',
  SLUG_PLAYLIST_ID: '[playlistId]',
  SLUG_GUEST_PASS: 'guest-pass',

  // utm source for content permissions
  SOURCE_PLAYER_PAGE_EMAIL_INVITATION: 'dailyMomentEmailDiscount',
  SOURCE_HOME_PAGE_EMAIL_INVITATION: 'welcomeEmailDiscount',

  PAGE_NAMES: {
    '/[type]': 'Content Type',
    '/[type]/[topic]': 'Content Type Topic',
    '/aura': 'Aura Home',
    '/challenges/[slugChallenge]': 'Challenge Landing Page',
    '/channels': 'Channels List',
    '/channels/[slugChannel]': 'Channel',
    '/coaches': 'Coaches List',
    '/coaches/[slugCoach]': 'Coach',
    '/coaches/[slugCoach]/tracks': 'Coach Tracks',
    '/coaches/[slugCoach]/channels': 'Coach Channels',
    '/coaches/[slugCoach]/coaching-session': 'Coach Session',
    '/coaches/[slugCoach]/courses/[slugCourse]': 'Course Landing Page',
    '/coaches/[slugCoach]/events/[slugEvent]': 'Event Landing Page',
    '/coaches/[slugCoach]/communities/[slugCommunity]':
      'Community Landing Page',
    '/coaches/[slugCoach]/live': 'Coach Live Event',
    '/coaches/[slugCoach]/video-coaching': 'Video Coaching Landing',
    '/coaching/[slugCoach]': 'Coaching Landing',
    '/coaching-plan/[slugCoach]': 'Coaching Plan',
    '/celebrities/[slugCelebrity]': 'Celebrity Landing Page',
    '/explore': 'Topics List',
    '/faq-delete-account': 'FAQ Delete Account Page',
    '/getapp': 'Get App',
    '/live/upcoming': 'Live Upcoming Events',
    '/live/[liveEventId]/replay': 'Live Event Replay',
    '/refer/new/[referralCode]': 'User Referral Landing Page',
    '/refer/[referralCode]/challenges/[challengeId]':
      'Challenge Referral Landing Page',
    '/refer/[referralCode]/guest-pass': 'Aura Guest Pass Referral Landing Page',
    '/refer/[referralCode]/live/[liveEventId]':
      'Aura Live Referral Landing Page',
    '/refer/[referralCode]/playlist/[...slug]':
      'Aura Playlist Referral Landing Page',
    '/refer/[referralCode]/tracks/[trackId]': 'Content Referral Landing Page',
    '/signup': 'Sign up start',
    '/subscribe/[pricing]': 'Subscription',
    '/subscribe/upsell': 'Upsell',
    '/topics': 'Topics List',
    '/topics/[slugCategory]': 'Topic',
    '/topics/[slugCategory]/[topic]': 'Topic Content Type',
    '/track/[slugMeditation]': 'Track',
    '/track/[slugMeditation]/similar-tracks': 'Similar Tracks',
    '/tracks': 'Tracks',
  },
};

module.exports = routeConstants;
